import {$, $$, on, addClass, removeClass, parseOptions, ready, attr, find, ajax, html} from 'uikit/src/js/util';
import UIkit from 'uikit';

function hide($el) {
    addClass($el, 'uk-hidden');
}

function show($el) {
    removeClass($el, 'uk-hidden');
}

ready(function() {

    var $subscribe = $('[data-rr-newsletter-subscribe]')
    console.log('subscribe', $subscribe);

    var options = attr($subscribe, 'data-rr-newsletter-subscribe');
    console.log('options', options);
    var options = parseOptions(options);
    console.log('options', options);

    var $email = $(options.email);
    console.log('email', $email);
    var $emailName = attr($email, 'name');
    console.log('emailName', $emailName);


    var $form = find($subscribe, 'form');
    console.log('$form', $form)
    var $form = find('form', $subscribe);
    console.log('$form', $form)
    var $loading = find('.loading', $subscribe);
    console.log('$loading', $loading)
    var $result = find('.result', $subscribe);
    var $resultContent = find('.result > div', $subscribe);
    console.log('$result', $result)
    //console.log('$result', $result);
    on($form, 'submit', function(e) {
        e.preventDefault();
        show($loading);
        var url = attr($form, 'action');
        //var $emailValue = attr($email, 'value');
        var $emailValue = $email.value;
        console.log('emailValue', $emailValue);
        //var $data = $form.serialize();
        //var $data = $emailName+'='+$emailValue;
        //var $data = {$emailName: $emailValue};
        var $data = 'email='+$emailValue;
        ajax(url, {
            method: "POST",
//            responseType: "json",
            url: url,
            data: $data,
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            },
            success: function(result) {
            }
        }).then(
            xhr => {

                console.log('xhr', xhr);

                console.log('xhr.responseText', xhr.responseText);

                var result = JSON.parse(xhr.responseText);

                console.log('result', result);
                if(result.error) {
                    html($resultContent, result.message);
                } else {
                    html($resultContent, result.message);
                }
                show($result);
                hide($loading);
            },
            e => this.error(e)
        );

        return false;
    });

});

/*
const $ = require('jquery');

$(function($) {
    var $subscribe = $('[data-rr-newsletter-subscribe]')
    if($subscribe.length) {
        var options = $subscribe.data('rr-newsletter-subscribe');
        //console.log('options', options);
        var $form = $subscribe.find('form');
        var $loading = $subscribe.find('.loading');
        var $result = $subscribe.find('.result');
        //console.log('$result', $result);
        $form.submit(function() {
            $loading.show();
            var url = $form.attr('action');
            $.ajax({
                type: "POST",
                dataType: "json",
                url: url,
                data: $form.serialize(),
                success: function(result) {
                    if(result.error) {
                        $result.html(result.message);
                    } else {
                        $result.html(result.message);
                    }
                    $result.show();
                    $loading.hide();
                }
            });

            return false;
        });
        //console.log('$result 2', $result);
    }
});
*/
