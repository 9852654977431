/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');


import UIkit from 'uikit';

import {$, $$, on, addClass, removeClass} from 'uikit/src/js/util';

import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

import FaIcons from './fa-regular-icons';

UIkit.use(FaIcons);

import SumernetIcons from './sumernet-icons';

UIkit.use(SumernetIcons);

//import FaIcons from './fa-regular-icons';
//UIkit.use(FaIcons);


require('./rr_infinite_scroll.js');
require('./youtube-modal.js');
require('./newsletter.js');
require('./autocomplete-search-global.js');

import Clamp from './truncate';

UIkit.component('clamp', Clamp);

//require('./truncate.js');


//import lottie from 'lottie-web';
