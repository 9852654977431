
import {$, $$, on, addClass, removeClass, parseOptions, ready, attr, css, html, data as getData, isNumber, width, height, append, remove} from 'uikit/src/js/util';
import UIkit from 'uikit';
import {debounce} from './utils';

ready(() => {
var $origModal = null;
var $modal = null;
var yvmCurrentWidth = null;
var yvmCurrentHeight = null;
function yvm(data) {

    function formatNumberWithCommas(e) {
        return e.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }

    function formatBigNumber(e, t) {
        if (e = parseFloat(e), t = t || 1, isNumber(e)) return NaN;
        var i, n, a = "";
        return e >= 1e9 ? (i = e / 1e9, a = "b") : e >= 1e6 ? (i = e / 1e6, a = "m") : e >= 1e3 ? (i = e / 1e3, a = "k") : i = e, i = i.toFixed(t), n = parseInt(i, 10), i - n === 0 && (i = n), i + a
    }

    function nl2br(e) {
        return e.replace(/\n/g, "<br>")
    }
    function formatAnchors(e) {
        return e.replace(/(https?|ftp):\/\/[^\s\t<]+/g, function(e) {
            return '<a href="' + e + '" target="_blank" rel="nofollow">' + e + "</a>"
        })
    }

    var origWidth = 640;
    var origHeight = 360;

    var width = 900;
    var height = origHeight / origWidth * width;
    yvmCurrentWidth = width;
    yvmCurrentHeight = height;
    console.log('yvm yvmCurrentWidth', yvmCurrentWidth);


    $modal = $('#youtube-modal .video-modal');

    if($origModal == null) {
        $origModal = html($modal);
    }
    console.log('$origModal', $origModal);
    html($modal, $origModal);

    var $subscribeButton = $('.channel-subscribe-button', $modal);
    /*
    $modal.find('.vframe').attr('src', data.embedUrl);
    */
    var $player = html($('.player', $modal), '<iframe src="//www.youtube.com/embed/'+data.id+'?rel=0" width="'+width+'" height="'+height+'" style="max-width:100%;" allowfullscreen></iframe>');

    /*
    var ytplayer = new YT.Player(t.$video.find(".yottie-popup-video-player span").get(0), {
        videoId: data.id,
        playerVars: {
            autoplay: 1,
            showinfo: !1
        },
        events: {
            onStateChange: function(e) {
                switch (e.data) {
                    case YT.PlayerState.ENDED:
                        e.target.pauseVideo(), e.target.seekTo(0)
                }
            }
        }
    });
    */

    html($('.title', $modal), '<a href="'+data.url+'" target="_blank">'+data.title+'</a>');
    //$modal.find('.channel').html('<div class="g-ytsubscribe" data-channelid="UCEZHTWresf9NV3GYG6DjxLw" data-layout="full" data-count="default"></div>')
    html($('.description', $modal), formatAnchors(nl2br(data.description)));
    html($('.views', $modal), formatNumberWithCommas(data.viewCount));
    html($('.likes', $modal), formatBigNumber(data.likeCount));
    html($('.dislikes', $modal), formatBigNumber(data.dislikeCount));
    css($('.rating-ratio span', $modal), 'width', parseInt(100 * data.likeCount / (parseInt(data.likeCount, 10) + parseInt(data.dislikeCount, 10)), 10)+'%');
    html($('.date', $modal), data.date);


    function processYtSubscribeButtonClick(event) {
        console.log(event);
    }
    function renderYtSubscribeButton(container, channel) {
        var options = {
            'channelId': channel,
//            'layout': 'full',
//            'onytevent': processYtSubscribeButtonClick
        };
        ///window.gapi.ytsubscribe.render(container.get(0), options);
    }

    renderYtSubscribeButton($subscribeButton, data.channelId);

    $modal.blur();

    var $uiModal = UIkit.modal("#youtube-modal");
    $uiModal.show();
    yvmFitSize();
}

console.log('yt-m', $("#youtube-modal"));

on($("#youtube-modal"), 'hide.uk.modal', () => {
        yvmCurrentHeight = null;
        yvmCurrentWidth = null;
        var $modal = $('#youtube-modal .video-modal');
        $modal.html($origModal);
    }
);

function yvmFitSize() {
    // calculate width

    var $dialog = $('#youtube-modal .uk-modal-dialog')
    var $container = $('#youtube-modal');
    var $content = $('#youtube-modal .video-modal');
    var //pad      = $dialog.outerWidth() - $dialog.width(),
        dpadTop  = parseInt(css($dialog, 'margin-top'), 10),
        dpadBot  = parseInt(css($dialog, 'margin-bottom'), 10),
        dpad     = dpadTop + dpadBot;

    var //pad      = $dialog.outerWidth() - $dialog.width(),
        padLeft  = parseInt(css($container, 'padding-left'), 10),
        padRight  = parseInt(css($container, 'padding-right'), 10),
        pad     = padLeft + padRight;


    var tmp = $('<div id="tmp">&nbsp;</div>');
    css(tmp, {
        opacity   : 0,
        position  : 'absolute',
        top       : 0,
        left      : 0,
        width     : '100%',
        maxWidth  : css($dialog, 'max-width'),
        padding   : css($dialog, 'padding'),
        margin    : css($dialog, 'margin')
    });
    console.log('yvmCurrentWidth', yvmCurrentWidth);
    var maxwidth, maxheight, w = yvmCurrentWidth, h = yvmCurrentHeight;
    console.log('w', w);

    append($('body'), tmp);

    maxwidth  = width(tmp);
    console.log('pad', pad);
    console.log('maxwidth', maxwidth);
    maxwidth  = maxwidth - pad;
    console.log('maxwidth', maxwidth);
    maxheight = window.innerHeight - dpad;
    console.log('yvmCurrentWidth', yvmCurrentWidth);
    console.log('maxheight', maxheight);

    remove(tmp);

    if (maxwidth < yvmCurrentWidth) {

        h = Math.floor( h * (maxwidth / w) );
        w = maxwidth;
    }


    /*
    if (maxheight < h) {

        h = Math.floor(maxheight);
        w = Math.ceil(yvmCurrentWidth * (maxheight/yvmCurrentHeight));
    }
    */

    console.log('yvmCurrentWidth', yvmCurrentWidth);
    console.log('yvmCurrentHeight', yvmCurrentHeight);
    console.log('w', w);
    console.log('w', h);

    width($content, w);

    height($('#youtube-modal .player iframe'), h);
}

on(window, 'load resize orientationchange', debounce((e) => {

    if(yvmCurrentWidth != null) {
        yvmFitSize();
    }

}, 100));


on($$('[data-rr-youtube-modal]'), 'click', (e) => {
    console.log('rr-youtube-modal start');
    console.log('e', e);
    e.preventDefault();
    var $this = $(e.currentTarget);
    console.log('click $this', $this);
    var $data = getData($this, 'rr-youtube-modal');
    console.log('$data', $data);
    $data = parseOptions($data);
    console.log('$data', $data);
    yvm($data);
});
});
