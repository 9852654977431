import {$, $$, on, addClass, removeClass, parseOptions, ready, attr, trigger} from 'uikit/src/js/util';
import UIkit from 'uikit';
const InfiniteScroll = require('infinite-scroll');

ready(function() {

    var $isl = $$('[data-rr-infinite-scroll]');
    console.log('$isl', $isl);
    $isl.forEach(function(el) {
        var $this = $(el);
        console.log('$this', $this);
        var options = attr($this, 'data-rr-infinite-scroll');
        console.log('options', options);
        var options = parseOptions(options);


        console.log('options', options);

        //$this.infiniteScroll(options);


        var infScroll = new InfiniteScroll(el, options);

        infScroll.on('append', function() {
            console.log('trigger display check');
            trigger($this,'display.uk.check');
            //$('.tm-sideheader').trigger('display.uk.check');
            trigger(UIkit.$win, 'resize');
        });

    });
});
