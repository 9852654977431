import generateRoute from './generate-route-i18n';

export default function(item) {

    console.log("searchroute: item: ", item);

    var uri = '';
    var routeName = '';
    var parameters = {};
    switch(item.objectClass) {
        case 'App\\Entity\\Story':
        case 'App\\Entity\\StoryPodcast':
            routeName = 'story_show';
            parameters = {
                slug: item.slug,
            };
        break;
        case 'App\\Entity\\Project':
            routeName = 'project_show';
            parameters = {
                slug: item.slug,
            };
        break;
        case 'App\\Entity\\Publication':
        case 'App\\Entity\\PublicationDownload':
        case 'App\\Entity\\PublicationLink':
                routeName = 'publication_show';
            parameters = {
                slug: item.slug,
            };
        break;
        case 'App\\Entity\\People':
            routeName = 'people_show';
            parameters = {
                category: item.categorySlug,
                slug: item.slug,
            };
        break;
        default:
            console.log('searchroute route for item.objectClass not implemented', item.objectClass);
        break;
    }

    console.log("searchroute: routeName: ", routeName);
    console.log("searchroute: parameters: ", parameters);


    var uri = generateRoute(routeName, parameters);

    //return render(uri);
    return uri;
}
