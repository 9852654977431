import routing from './routing.js';
const Routing = routing();

export default function generateRoute(name, opt_params, absolute = false) {
    //console.log('name', name);
    //console.log('opt_params', opt_params);
    //console.log('absolute', absolute);
    var uri = Routing.generate(name, opt_params, absolute);
    //console.log('uri', uri);
    return uri;
}
