//import Class from 'uikit/src/js/mixin/class';
import {addClass, css, hasClass, height as getHeight, isRtl, scrolledOver, toFloat, toggleClass, toNodes, Transition, sortBy} from 'uikit/src/js/util';

//import {$, $$, on, addClass, removeClass, parseOptions, ready, attr} from 'uikit/src/js/util';
import UIkit from 'uikit';
var Class = UIkit.mixin('class');
const $clamp = require('clamp-js');

/*
ready(function() {

    var $l = $$('[data-rr-truncate]');
    $l.forEach(function(el) {
        var $this = $(el);
        console.log('$this', $this);
        var options = attr($this, 'data-rr-truncate');
        console.log('options', options);
        var options = parseOptions(options);
        console.log('options', options);
        $clamp($this, options);
    });

    on(window,


});
*/

export default {

    //mixins: [Class],

    name: 'clamp',

    props: {
        clamp: String,
        debug: Boolean,
    },

    data: {
        clamp: 3,
    },

    update: [
        {

            read() {
                //console.log('clamp read');
                $clamp(this.$el, {clamp: this.clamp});
            },

            write() {
                //console.log('clamp write');
                $clamp(this.$el, {clamp: this.clamp});
            },

            events: ['scroll', 'resize']

        }

    ]

};

