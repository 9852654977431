import {$, $$, on, addClass, removeClass, parseOptions, ready, attr, css, html, data as getData, isNumber, width, height, append, remove} from 'uikit/src/js/util';
import generateRoute from './generate-route';

const $html = $('html');
const locale = getData($html, 'locale');
const routingPrefix = getData($html, 'routing-prefix');


export default function generateRouteI18n(routeName, parameters, absolute = false) {
    //console.log('routeName', routeName);
    routeName = locale+routingPrefix+routeName;
    //console.log('routeName', routeName);

    var uri = generateRoute(routeName, parameters);
    return uri;
}
