import {$, on} from 'uikit/src/js/util';


const algoliasearch = require('algoliasearch/lite');
const autocomplete = require('autocomplete.js');

import generateRoute from './searchroute';


const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)

//const client = algoliasearch("4J2JTO3FLK", "2f4cff0198aca3ee49fffa6df138e260");
const global = client.initIndex(process.env.ALGOLIA_SEARCH_PREFIX+'global');

var $gsi = $('#global-search');
var $gsf = $('#global-search-form')

on($gsi, 'keypress', function(event) {
    if (event.keyCode == 13 || event.which == 13) {
        $gsf.submit();
    }
});

autocomplete('#global-search', {}, [
    {
      source: autocomplete.sources.hits(global, { hitsPerPage: 5 }),
      displayKey: 'g_name',
      templates: {
        suggestion({_highlightResult}) {
          return `<span>${_highlightResult.g_name.value}</span>`;
        }
      }
    },
]).on('autocomplete:selected', function(event, suggestion, dataset) {
    //console.log(suggestion, dataset);
    //alert('dataset: ' + dataset + ', ' + suggestion.name);
    var uri = false;
    var uri = generateRoute(suggestion);
    //console.log('uri', uri);
    window.location.href = uri;
});
